<template>
    <header>
        <div class="row align-items-center m-0">
            <div class="col-3 col-lg-2 col-xl-2 p-0 text-center text-lg-end">
                <router-link to="/">
                    <img :src="logo_url.value" alt="Logo" title="Logo">
                </router-link>
            </div>
            <div class="col-6 col-lg-7 col-xl-8 p-0">
                <div class="nav-bar" :class="{ expanded: navExpand }">
                    <button class="btn toggler" @click="toggleNav" type="button">
                        <span><font-awesome-icon :icon="navExpand ? 'fa-solid fa-times' : 'fa-solid fa-bars'" /></span>
                    </button>
                    <ul class="nav-menu">
                        <li class="nav-item" v-for="main in main_menu" :key="main.key">
                            <div class="dropdown" :class="{ expanded: dropdownExpand }">
                                <a v-if="main.item_url.startsWith('http')" :href="main.item_url">
                                    {{ main.item_title }}
                                    <font-awesome-icon v-if="main.subItems" :icon="'fa-solid fa-caret-down'" />
                                </a>
                                <router-link v-else :to="{ path: main.item_url }" active-class="active">
                                    {{ main.item_title }}
                                    <font-awesome-icon v-if="main.subItems" :icon="'fa-solid fa-caret-down'" />
                                </router-link>
                                <ul class="dropdown-menu">
                                    <li v-for="main_sub in main.subItems" :key="main_sub.key">
                                        <a v-if="main_sub.item_url.startsWith('http')" :href="main_sub.item_url" class="dropdown-item"><b>{{
                                                main_sub.item_title }}</b></a>
                                        <router-link v-else :to="{ path: main_sub.item_url }" class="dropdown-item"><b>{{
                                                main_sub.item_title }}</b></router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <!-- <li>
                            <div class="dropdown" :class="{ expanded: dropdownExpand }">
                                <a @click="toggleDropdown"
                                    :href="navExpand ? 'javascript:void(0);' : 'https://disappearance.egyclimate.net/%d8%a7%d9%84%d9%85%d9%83%d8%aa%d8%a8%d8%a9/'"><b>
                                        {{ $t("nav.library") }}<font-awesome-icon icon="fa-solid fa-caret-down" /></b>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a href="https://disappearance.egyclimate.net/%d8%a3%d8%ae%d8%a8%d8%a7%d8%b1/"
                                            class="dropdown-item"><b>{{
                                                $t("nav.news") }}</b></a>
                                    </li>
                                    <li>
                                        <a href="https://disappearance.egyclimate.net/%d8%aa%d9%82%d8%a7%d8%b1%d9%8a%d8%b1/"
                                            class="dropdown-item"><b>{{ $t("nav.reports") }}</b></a>
                                    </li>
                                    <li>
                                        <a href="https://disappearance.egyclimate.net/%d9%85%d9%82%d8%a7%d9%84%d8%a7%d8%aa/"
                                            class="dropdown-item"><b>{{ $t("nav.articles") }}</b></a>
                                    </li>
                                    <li>
                                        <a href="https://disappearance.egyclimate.net/%d8%aa%d9%82%d8%a7%d8%b1%d9%8a%d8%b1-%d8%a7%d9%84%d9%81%d8%b1%d9%8a%d9%82-%d8%a7%d9%84%d8%b9%d8%a7%d9%85%d9%84/"
                                            class="dropdown-item"><b>{{ $t("nav.staff-reports") }}</b></a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <router-link to="/" active-class="active"><b>{{ $t("nav.map") }}</b></router-link>
                        </li>
                        <li>
                            <a href="https://disappearance.egyclimate.net/%d8%a7%d8%aa%d8%b5%d9%84-%d8%a8%d9%86%d8%a7/"><b>{{
                                $t("contact") }}</b></a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-3 col-lg-2 col-xl-1 p-0">
                <div class="search">
                    <Button @btnClick="$emit('btnClick')" text="" icon="fa-solid fa-search" textColor="white" />
                </div>
            </div>
            <div class="col-12 col-lg-1 col-xl-1 p-0">
                <div class="actions">
                    <a :href="report_case.value" class="btn btn-secondary">{{ $t('nav.report') }}</a>
                    <!-- <Button @btnClick="$emit('btnClick')" text="nav.report" borderColor="white" bgColor="black"
                    textColor="white" /> 
                    <button class="lang-switch"
                        @click.prevent="changeLanguage(($i18n.locale == 'ar') ? 'en' : 'ar', ($i18n.locale == 'ar') ? 'en' : 'ar')">
                        {{ ($i18n.locale == 'ar') ? 'en' : 'العربية' }}
                    </button> -->
                </div>
            </div>
        </div>
    </header>
</template>
<script>
import axios from 'axios'
import Button from "./Button.vue";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});
const base_url = process.env.VUE_APP_BASE_URL;
// const lang = process.env.VUE_APP_DEFAULT_LOCALE;

export default {
    name: 'Header',
    components: {
        Button,
    },
    data() {
        return {
            languages: [],
            navExpand: false,
            dropdownExpand: false,
            report_case: '',
            logo_url: '',
            main_menu: [],
        }
    },
    beforeMount() {
        this.get_languages();
        this.get_report_case();
        this.get_logo_img();
        this.get_menu();
    },
    methods: {
        changeLanguage(locale, name) {
            this.$i18n.locale = locale;
            this.selectedLanguage = locale;
            // this.languageDefult.name = name;
            localStorage.setItem("lang", this.$i18n.locale);
            location.reload();
        },
        get_languages() {
            axios
                .get(base_url + "/get_languages")
                .then((response) => {
                    this.languages = response.data.data;
                })
                .catch((error) => {
                    let res = error.response;
                    toaster.error(res.data.message);
                });
        },
        get_report_case() {
            axios
                .get(base_url + "/get_setting/report_case_url")
                .then((response) => {
                    this.report_case = response.data.data;
                })
                .catch((error) => {
                    let res = error.response;
                    toaster.error(res.data.message);
                });
        },
        get_logo_img() {
            axios
                .get(base_url + "/get_setting/logo_image")
                .then((response) => {
                    this.logo_url = response.data.data;
                })
                .catch((error) => {
                    let res = error.response;
                    toaster.error(res.data.message);
                });
        },
        toggleNav() {
            this.navExpand = !this.navExpand
        },
        toggleDropdown() {
            this.dropdownExpand = !this.dropdownExpand
        },
        get_menu() {
            var params = {
                slug: "main_menu",
                locale: process.env.VUE_APP_DEFAULT_LOCALE,
            };
            axios
                .get(base_url + "/getMenuBySlug", { params: params })
                .then((response) => {
                    this.main_menu = response.data.data;
                })
                .catch((error) => {
                    let res = error.response;
                    toaster.error();
                });
        },
    }
};
</script>

<style scoped>
header {
    position: relative;
    padding: 10px 4rem;
    background-color: black;
    z-index: 1000;
}

header img {
    width: 45%;
}

.nav-bar ul:not(.dropdown-menu) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
}

.nav-bar ul.nav-menu {
    padding: 0;
}

.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.75rem 1.25rem;
    margin: 0;
    transition: 0.3s;
    color: white;
    text-decoration: none;
    transition: 0.3s ease-in-out;
}

.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item)::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: transparent;
    opacity: 0;
    transition: 0.3s;
}

.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item).active,
.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item):hover {
    color: #e6d80b;
}

.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item).active::before,
.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item):hover::before {
    background-color: #e6d80b;
    opacity: 1;
}

.nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item) b {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.dropdown:hover .dropdown-menu {
    display: block;
    padding: 0;
}

ul.dropdown-menu {
    border-radius: 0;
    min-width: unset;
}

ul.dropdown-menu li a.dropdown-item {
    text-align: start;
    padding: 0.8rem 1.75rem 0.8rem 1.25rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: black;
    transition: 0.3s ease-in-out;
}

ul.dropdown-menu li a.dropdown-item:hover {
    color: white;
    background-color: #3f444b;
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search a {
    position: relative;
    color: white;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.actions .lang-switch {
    font-family: 'Noto Kufi Arabic', sans-serif;
    background: none;
    border: none;
    color: white;
}

.rtl .actions .lang-switch {
    font-family: 'Aldrich', sans-serif;
}

.toggler {
    display: none;

}

.search button.btn:hover {
    background: none !important;
    color: white !important;
}

@media(max-width: 992px) {
    header {
        flex-wrap: wrap;
        padding: 0.75rem 0 0 0;
    }

    .nav-bar {
        display: grid;
        grid-template-rows: 35px 0fr;
        transition: 0.3s ease-in-out;
    }

    .nav-bar.expanded {
        grid-template-rows: 35px 1fr;
    }

    .nav-bar ul.nav-menu {
        position: relative;
        background-color: white;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0;
        width: 100%;
        overflow: hidden;
        transition: 0.3s ease-in-out;
    }

    ul.dropdown-menu {
        position: relative;
        border: none;
    }

    ul.dropdown-menu li a.dropdown-item {
        text-align: center;
    }

    header img {
        width: 105px;
    }

    /* .dropdown a {
        pointer-events: none;
    } */

    .actions {
        margin: 0.75rem 0;
    }

    .nav-bar .navbar-toggler {
        display: block;
    }

    .nav-bar .dropdown {
        display: grid;
        grid-template-rows: 0fr 0fr;
        transition: 0.3s ease-in-out;
    }

    .nav-bar .dropdown.expanded {
        grid-template-rows: 40px 1fr;

    }

    .nav-bar .dropdown .dropdown-menu {
        overflow: hidden;
    }

    .nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item) {
        justify-content: center;
        color: black;
        padding: 0.7rem;
        font-size: 0.75rem;
    }

    .nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item).active,
    .nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item):hover {
        background-color: #3f444b;
        color: white;
        width: 100%;
    }

    .nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item).active:before,
    .nav-bar ul:not(.dropdown-menu) li a:not(.dropdown-item):hover:before {
        opacity: 1;
        background-color: #3f444b;
    }

    .nav-bar {
        position: relative;
        width: 100%;
    }

    .btn.toggler {
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding: 0 0.5rem !important;
        margin: 0 auto 0.75rem;
        height: 100%;
    }

    .btn.toggler span {
        font-size: 1.5rem;
        display: block;
        color: white;
    }

    .btn.toggler:focus span {
        color: black;
    }
}
</style>